import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NumericInput from 'react-numeric-input';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MachineConfig () {
  const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

  const params = useParams()
  const id = params.id
  const navigate = useNavigate();
  const [errore, setErrore] = useState([]);

  const [data, setData] = useState({
    machine_name: '',
    seuil_usinage_max: 0,
    seuil_usinage_min: 0,
    seuil_reglage_max: 0,
    seuil_reglage_min: 0,
    reglage: false,
    type_machine: false,
    id: id
  });

  const {
    machine_name,
    seuil_usinage_max,
    seuil_usinage_min,
    seuil_reglage_max,
    seuil_reglage_min,
    reglage,
    type_machine,
  } = data

  function formatCourant(num) {
    return num + 'A';
  }
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
  
    setData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };


  const handleEditSubmit = async () => {
    const formData = new FormData()

      formData.append('machine_name', machine_name)
      formData.append('id_machine', data.id)
      formData.append('seuil_usinage_max', seuil_usinage_max)
      formData.append('seuil_usinage_min', seuil_usinage_min)
      formData.append('type_machine', type_machine)
      // console.log('Initial state:', data);

      formData.append('reglage', reglage)
      if(reglage == true){
        formData.append('seuil_reglage_max', seuil_reglage_max)
        formData.append('seuil_reglage_min', seuil_reglage_min)
      }


      try {
        await axios.post(
          process.env.REACT_APP_BACKEND_IP
          + ':' + process.env.REACT_APP_BACKEND_PORT
          + '/users/usine/saveConfigMachine/'
          , formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            },
        }).then(res => {
          if (res.status == 200 ) {
            toast.success(res.data.status)
            setTimeout(() => {
              navigate("/gestionUsine")
            }, 3000);
          }

          if (res.status == 500 ) {
            toast.error(res.data.status)
          }
        })

      } catch (error) {
        console.log(error)
      }
  }

  useEffect(() => {
    async function fetchparams() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/users/usine/getConfigMachineById/${id}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );

        const configMachine = response.data;

        if (configMachine) {
          setData((prevState) => ({
            ...prevState,
            machine_name: configMachine.machine_name || '',
            seuil_usinage_max: configMachine.seuil_usinage_max,
            seuil_usinage_min: configMachine.seuil_usinage_min,
            type_machine: configMachine.type_machine,
            reglage: configMachine.reglage !== undefined ? configMachine.reglage : false,
            ...(configMachine.reglage
              ? {
                  seuil_reglage_max: configMachine.seuil_reglage_max,
                  seuil_reglage_min: configMachine.seuil_reglage_min,
                }
              : {}),
          }));
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchparams();
  }, [id, token]);


  return (
    <>
    {}
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="gestionMachines" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Modifier paramètres </h1>
                  </div>
                </div>

                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-bottom">
                        <h3 className="card-title">{"Machine : " + machine_name}</h3>
                      </div>
                      <div className="card-body">
                      <div className="form-group mb-4">
                        <div className="row">
                            <label className="form-label col-md-2 col-lg-2 col-xl-3">
                            Nom Machine
                            </label>
                            <div className="form-group col-md-3 col-lg-3 col-xl-6">
                            <input
                                type="text"
                                value={machine_name}
                                className="form-control"
                                onChange={(e) => handleChange(e)}
                                name="machine_name"
                                placeholder="Enter machine name"
                            />
                            </div>
                          </div>
                          <div className='row'>
                            <label className="form-label col-md-2 col-lg-2 col-xl-3">Type Machine</label>
                            <div className="col-md-5 col-lg-5 col-xl-4">
                              <div className="">
                                <input
                                  type="radio"
                                  id="monophase"
                                  name="type_machine"
                                  checked={type_machine === true} // False = Monophase
                                  onChange={() => handleChange({ target: { name: "type_machine", value: true } })}
                                />
                                <label className="form-label-checkbox">
                                  MONOPHASE
                                </label>
                              </div>
                            </div>
                            <div className="col-md-5 col-lg-5 col-xl-4">
                              <div className="">
                                <input
                                  type="radio"
                                  id="triphase"
                                  name="type_machine"
                                  checked={type_machine === false} // True = Triphase
                                  onChange={() => handleChange({ target: { name: "type_machine", value: false } })}
                                />
                                <label className="form-label-checkbox">
                                  TRIPHASE
                                </label>
                              </div>
                            </div>
                          </div>
                        <div className="row">
                            <label className="form-label col-md-2 col-lg-2 col-xl-3">
                            Seuil Usinage
                            </label>
                            <div className="form-group col-md-3 col-lg-3 col-xl-3">
                            <NumericInput
                                className="form-control"
                                value={seuil_usinage_min}
                                placeholder="Saisir seuil min"
                                name="seuil_usinage_min"
                                min={0}
                                step={0.1}
                                format={formatCourant}
                                onChange={seuil_usinage_min => handleChange({ target: { value: seuil_usinage_min, name: 'seuil_usinage_min' } })}
                                onKeyPress={e => {
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                                }}
                            />
                            </div>
                            <div className="form-group col-md-3 col-lg-3 col-xl-3">
                            <NumericInput
                                className="form-control"
                                value={seuil_usinage_max}
                                placeholder="Saisir seuil max"
                                name="seuil_usinage_max"
                                min={0}
                                step={0.1}
                                format={formatCourant}
                                onChange={seuil_usinage_max => handleChange({ target: { value: seuil_usinage_max, name: 'seuil_usinage_max' } })}
                                onKeyPress={e => {
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                                }}
                            />
                            </div>
                            <label className="form-label col-md-2 col-lg-2 col-xl-2">
                            Réglage
                            </label>
                            <div className="material-switch col-md-1 col-lg-1 col-xl-1">
                            <input
                                id={`checkedDangerSwitch`}
                                name="reglage"
                                type="checkbox"
                                onChange={handleChange}
                                checked={reglage || false}
                            />
                            <label
                                htmlFor={`checkedDangerSwitch`}
                                className="label-danger"
                            ></label>
                            </div>
                        </div>
                
                        <div className="row">
                            {reglage && (
                            <>
                                <label className="form-label col-md-2 col-lg-2 col-xl-3">
                                Seuil Réglage
                                </label>
                                <div className="form-group col-md-3 col-lg-3 col-xl-3">
                                <NumericInput
                                    className="form-control"
                                    value={seuil_reglage_min}
                                    placeholder="Saisir seuil min"
                                    name="seuil_reglage_min"
                                    min={0}
                                    step={0.1}
                                    format={formatCourant}
                                    onChange={seuil_reglage_min => handleChange({ target: { value: seuil_reglage_min, name: 'seuil_reglage_min' } })}
                                    onKeyPress={e => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                    }}
                                />
                                </div>
                                <div className="form-group col-md-3 col-lg-3 col-xl-3">
                                <NumericInput
                                    className="form-control"
                                    value={seuil_reglage_max}
                                    placeholder="Saisir seuil max"
                                    name="seuil_reglage_max"
                                    min={0}
                                    step={0.1}
                                    format={formatCourant}
                                    onChange={seuil_reglage_max => handleChange({ target: { value: seuil_reglage_max, name: 'seuil_reglage_max' } })}
                                    onKeyPress={e => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                    }}
                                />
                                </div>
                            </>
                            )}
                        </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                          <div className="'col-md-12 col-lg-12 col-xl-2">
                            <button className="btn btn-outline-warning btn-block" type="submit" onClick={handleEditSubmit}>
                              Modifier
                            </button>
                            <ToastContainer
                              className="toast-container"
                              autoClose={2000}
                              closeButton={false}
                              theme={"colored"}
                              icon={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default MachineConfig;




// const [data, setData] = useState({
//     testImage: "",
//     machines: [
//       { 
//         machineSettings: [
//           {
//             machine_name: "",
//             seuil_usinage_max: 0,
//             seuil_usinage_min: 0,
//             seuil_reglage_max: 0,
//             seuil_reglage_min: 0,
//             reglage: false,
//           },
//         ],
//       }
//     ],
//   });
  
//   const { testImage, machines } = data;
  
//   const handleChange = (machineIndex, settingIndex, name, value) => {
//     const updatedSettings = machines[machineIndex].machineSettings.map((setting, i) =>
//       i === settingIndex ? { ...setting, [name]: value } : setting
//     );
//     const updatedMachines = machines.map((machine, i) =>
//       i === machineIndex ? { ...machine, machineSettings: updatedSettings } : machine
//     );
//     setData({
//       ...data,
//       machines: updatedMachines
//     });
//   };
  
  // Your component's render method
//   {usineData.departures && usineData.departures.length > 0 && usineData.departures.map((departure, machineIndex) => (
//     departure.machines.map((setting, settingIndex) => (
//       <React.Fragment key={settingIndex}>
//         <div className="form-group mb-4">
//           <div className="row">
//             <label className="form-label col-md-2 col-lg-2 col-xl-3">
//               Nom Machine
//             </label>
//             <div className="form-group col-md-3 col-lg-3 col-xl-6">
//               <input
//                 type="text"
//                 value={setting.machine_name}
//                 className="form-control"
//                 onChange={(e) => handleChange(machineIndex, settingIndex, 'machine_name', e.target.value)}
//                 name="machine_name"
//                 placeholder="Enter machine name"
//               />
//             </div>
//             <label className="form-label col-md-2 col-lg-2 col-xl-2">
//               Réglage
//             </label>
//             <div className="material-switch col-md-1 col-lg-1 col-xl-1">
//               <input
//                 id={`checkedDangerSwitch-${settingIndex}`}
//                 name="reglage"
//                 type="checkbox"
//                 onChange={(e) => handleChange(machineIndex, settingIndex, 'reglage', e.target.checked)}
//                 checked={setting.reglage}
//               />
//               <label
//                 htmlFor={`checkedDangerSwitch-${settingIndex}`}
//                 className="label-danger"
//               ></label>
//             </div>
//           </div>
//           <div className="row">
//             <label className="form-label col-md-2 col-lg-2 col-xl-3">
//               Seuil Usinage
//             </label>
//             <div className="form-group col-md-3 col-lg-3 col-xl-3">
//               <NumericInput
//                 className="form-control"
//                 value={setting.seuil_usinage_min}
//                 placeholder="Saisir seuil min"
//                 name="seuil_usinage_min"
//                 min={0}
//                 step={0.1}
//                 format={formatCourant}
//                 onChange={(value) => handleChange(machineIndex, settingIndex, 'seuil_usinage_min', value)}
//                 onKeyPress={e => {
//                   if (!/[0-9]/.test(e.key)) {
//                     e.preventDefault();
//                   }
//                 }}
//               />
//             </div>
//             <div className="form-group col-md-3 col-lg-3 col-xl-3">
//               <NumericInput
//                 className="form-control"
//                 value={setting.seuil_usinage_max}
//                 placeholder="Saisir seuil max"
//                 name="seuil_usinage_max"
//                 min={0}
//                 step={0.1}
//                 format={formatCourant}
//                 onChange={(value) => handleChange(machineIndex, settingIndex, 'seuil_usinage_max', value)}
//                 onKeyPress={e => {
//                   if (!/[0-9]/.test(e.key)) {
//                     e.preventDefault();
//                   }
//                 }}
//               />
//             </div>
//           </div>
  
//           <div className="row">
//             {setting.reglage && (
//               <>
//                 <label className="form-label col-md-2 col-lg-2 col-xl-3">
//                   Seuil Réglage
//                 </label>
//                 <div className="form-group col-md-3 col-lg-3 col-xl-3">
//                   <NumericInput
//                     className="form-control"
//                     value={setting.seuil_reglage_min}
//                     placeholder="Saisir seuil min"
//                     name="seuil_reglage_min"
//                     min={0}
//                     step={0.1}
//                     format={formatCourant}
//                     onChange={(value) => handleChange(machineIndex, settingIndex, 'seuil_reglage_min', value)}
//                     onKeyPress={e => {
//                       if (!/[0-9]/.test(e.key)) {
//                         e.preventDefault();
//                       }
//                     }}
//                   />
//                 </div>
//                 <div className="form-group col-md-3 col-lg-3 col-xl-3">
//                   <NumericInput
//                     className="form-control"
//                     value={setting.seuil_reglage_max}
//                     placeholder="Saisir seuil max"
//                     name="seuil_reglage_max"
//                     min={0}
//                     step={0.1}
//                     format={formatCourant}
//                     onChange={(value) => handleChange(machineIndex, settingIndex, 'seuil_reglage_max', value)}
//                     onKeyPress={e => {
//                       if (!/[0-9]/.test(e.key)) {
//                         e.preventDefault();
//                       }
//                     }}
//                   />
//                 </div>
//               </>
//             )}
//           </div>
//         </div>
//       </React.Fragment>
//     ))
//   ))}
  