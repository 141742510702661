import React, { useState, useEffect, useMemo, useCallback } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import DatePicker from "../components/DatePicker";
import TimePicker from "../components/TimePicker";
import ReactHighcharts from 'react-highcharts/ReactHighstock.src';
import axios from "axios";
import { io } from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectOne from '../components/SelectOne';

const ChartPression = ({ data }) => {
    return (
        <div className="card">
            <div className="card-body">
                <ReactHighcharts
                    config={{
                        chart: {
                            zoomType: 'x',

                        },
                        title: {
                            text: "Pression",
                        },
                        xAxis: {
                            type: 'datetime'
                        },

                        rangeSelector: {
                            selected: 3,
                            inputEnabled: true,
                            buttons: [{
                                type: 'day',
                                count: 1,
                                text: '1j'
                            }, {
                                type: 'week',
                                count: 1,
                                text: '1s'
                            }, {
                                type: 'month',
                                count: 1,
                                text: '1m'
                            }, {
                                type: 'all',
                                text: 'Tous'
                            }]
                        },
                        tooltip: {
                            pointFormat: 'Pression : {point.y:.3f}',
                        },

                        series: [{
                            name: "Pression",
                            data: data,
                            type: "spline",
                            color: '#000000',
                            lineWidth: 1
                        }]
                    }}


                />
            </div>
        </div>

    );
};

function Pression() {
    const token = localStorage.getItem('jwt'); // Retrieve the token from localStorage or your state management

    const [socketConnected, setSocketConnected] = useState(true);
    const [compressors, setCompressors] = useState([]);

    const setCompresseurData = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_BACKEND_IP +
                ':' +
                process.env.REACT_APP_BACKEND_PORT +
                '/pression/getCompresseursList',
                {
                    headers: {
                      'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                    }
                  }
            );
            const compresseurs = response.data.compresseurs;


            const initialCompressors = compresseurs.map(compressor => ({
                id_machine: compressor.id_machine,
                name_machine: compressor.name_machine,
                pression: 0,
                time: '--',
            }));


            //   if (localStorage.getItem('data_compresseur') === null) {
            //     localStorage.setItem('data_compresseur', JSON.stringify(initialCompressors));
            //   }

            setCompressors(initialCompressors);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const storedCompressors = JSON.parse(localStorage.getItem('data_compresseur'));
        if (storedCompressors == []) {
            setCompressors(storedCompressors);
        } else {
            setCompresseurData();
        }
    }, []);

    const updateCompressors = (data) => {
        setCompressors(compressors => {
            const updatedCompressors = compressors.map(compressor => {
                if (compressor.id_machine === data.machine.id_machine) {
                    const timestamp = data.time.time;
                    const date = new Date(timestamp);
                    var hours = date.getUTCHours();
                    var minutes = date.getUTCMinutes();
                    var seconds = date.getUTCSeconds();

                    if (hours < 10)
                        hours = "0" + hours
                    if (minutes < 10)
                        minutes = "0" + minutes
                    if (seconds < 10)
                        seconds = "0" + seconds

                    const formattedTime = `${hours}:${minutes}:${seconds}`;
                    return {
                        ...compressor,
                        pression: data.pression,
                        time: formattedTime,
                    };
                }
                return compressor;
            });
            return updatedCompressors;
        });


        localStorage.setItem('data_compresseur', JSON.stringify(compressors));
    };

    useEffect(() => {
        const socket = io(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/`);
        socket.on('connect', () => {
            setSocketConnected(true);
        });

        socket.on('disconnect', () => {
            setSocketConnected(false);
        });

        let lastDataTimestamp = null;
        const oneMinuteInMillis = 60000; // One minute 

        socket.on('data', (data) => {
            if (data.hasOwnProperty('pression')) {
                lastDataTimestamp = new Date().getTime();
                updateCompressors(data);
            } else {

                const currentTime = new Date().getTime();
                if (lastDataTimestamp !== null && currentTime - lastDataTimestamp >= oneMinuteInMillis) {

                    const initialData = {
                        pression: 0,
                        time: '--',
                    };
                    setCompressors(prevCompressors => (
                        prevCompressors.map(compressor => ({
                            ...compressor,
                            ...initialData,
                        }))
                    ));
                }
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);
    const [selected, setSelected] = useState({
        compresseurs: "",
        annee_fin: "",
        mois_fin: "",
        jour_fin: "",
        annee_debut: "",
        mois_debut: "",
        jour_debut: "",
        timeDebut: "",
        timeFin: "",
    });
    const [selectedDate, setSelectedDate] = useState({
        fin: "",
        debut: "",
    });
    const [selectedTime, setSelectedTime] = useState({
        timeDebut: "",
        timeFin: "",
    });
    const [showCard, setShowCard] = useState(false);
    const [DataChart, setDataChart] = useState(null);
    const [status, setStatus] = useState("");
    const handleDateChange = (date, type) => {
        var datee = new Date(date);
        var day = datee.getDate();
        var month = datee.getMonth() + 1;
        var year = datee.getFullYear();

        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }

        setSelectedDate((prevState) => ({ ...prevState, [type]: datee }));
        if (type == "fin") {
            setSelected((prevState) => ({
                ...prevState,
                annee_fin: year,
                mois_fin: month,
                jour_fin: day,
            }));
        } else {
            setSelected((prevState) => ({
                ...prevState,
                annee_debut: year,
                mois_debut: month,
                jour_debut: day,
            }));
        }
    };
    const DisplayChart = () => {
        setShowCard(true);
    };

    const handleTimeChange = (date, type) => {
        var time = new Date(date);
        var hour = time.getHours();
        var minutes = time.getMinutes();
        if (hour < 10) {
            hour = "0" + hour;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        var new_time = hour + ":" + minutes;
        setSelectedTime((prevState) => ({ ...prevState, [type]: time }));
        setSelected((prevState) => ({ ...prevState, [type]: new_time }));
    };
    const updateChartData = useCallback((chartData) => {
        if (chartData.status !== "empty") {
            setDataChart(chartData.data);
            setStatus(chartData.status);
            DisplayChart();
        } else {
            toast.error("Pas de données dans la période sélectionnée");
        }
    }, []);
    const handleChange = (event, type) => {
        setSelected(prevState => ({ ...prevState, [type]: event.value }))
    
      }
      const handleSubmit = async (e) => {

    var time_debut = "";
      var time_fin = "";
      try {
        if(selected.timeDebut==""){
        time_debut = "00:00";
      }else {
        time_debut = selected.timeDebut;
      }
      if(selected.timeFin==""){
        var time = new Date()
        var hour = time.getHours();
        var minutes = time.getMinutes()-1;
        if (hour < 10) {
          hour = "0" + hour
        }
        if (minutes < 10) {
          minutes = "0" + minutes
        }
        var new_time = hour + ':' + minutes
        time_fin  = new_time;
      }
      else {
        time_fin = selected.timeFin;
      }
        
      const response = await axios.get(process.env.REACT_APP_BACKEND_IP
        + ':' + process.env.REACT_APP_BACKEND_PORT
        + '/historique/checkDates/'
        + selected.mois_debut + '/'
        + selected.jour_debut + '/'
        + selected.annee_debut + '/'
        + time_debut + '/'
        + selected.mois_fin + '/'
        + selected.jour_fin + '/'
        + selected.annee_fin + '/'
        + time_fin,
        {
            headers: {
              'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
            }
          }
      );
      if (response.status === 200) {
        // prssion Time
        if (
            selected.annee_debut == selected.annee_fin &&
            selected.mois_debut == selected.mois_fin &&
            selected.jour_debut == selected.jour_fin &&
            selected.timeDebut !== '' &&
            selected.timeFin !== ''
        ) {
            try {
                const chartData = await axios.get(
                    process.env.REACT_APP_BACKEND_IP +
                    ":" +
                    process.env.REACT_APP_BACKEND_PORT +
                    "/pression/enregistrement_time_pression/" +
                    selected.compresseurs +
                    "/" +
                    selected.annee_debut +
                    "-" +
                    selected.mois_debut +
                    "-" +
                    selected.jour_debut +
                    "/" +
                    selected.annee_fin +
                    "-" +
                    selected.mois_fin +
                    "-" +
                    selected.jour_fin +
                    "/" +
                    selected.timeDebut +
                    "/" +
                    selected.timeFin,
                    {
                        headers: {
                          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                        }
                      }
                );

                updateChartData(chartData.data);
            } catch (error) {
                console.log(error);
            }
        }
        //date time Range  
        else if (selected.timeDebut !== '' &&
        selected.timeFin !== '' && selected.jour_debut !== selected.jour_fin){
            try {
                const chartData = await axios.get(
                    process.env.REACT_APP_BACKEND_IP +
                    ":" +
                    process.env.REACT_APP_BACKEND_PORT +
                    "/pression/getPressionDataForDateTimeRange/" +
                    selected.compresseurs +
                    "/" +
                    selected.annee_debut +
                    "-" +
                    selected.mois_debut +
                    "-" +
                    selected.jour_debut +
                    "/" +
                    selected.timeDebut +
                    "/" +
                    selected.annee_fin +
                    "-" +
                    selected.mois_fin +
                    "-" +
                    selected.jour_fin +
                    "/" +
                    selected.timeFin,
                    {
                        headers: {
                          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                        }
                      }
                );

                updateChartData(chartData.data);
            } catch (error) {
                console.log(error);
            }
        }
        //date Range 
        else if (selected.timeDebut === "" && selected.timeFin === ""){
            try {
                const chartData = await axios.get(

                    process.env.REACT_APP_BACKEND_IP +
                    ":" +
                    process.env.REACT_APP_BACKEND_PORT +
                    "/pression/enregistrement_history_pression/" +
                    selected.compresseurs +
                    "/" +
                    selected.annee_debut +
                    "-" +
                    selected.mois_debut +
                    "-" +
                    selected.jour_debut +
                    "/" +
                    selected.annee_fin +
                    "-" +
                    selected.mois_fin +
                    "-" +
                    selected.jour_fin,
                    {
                        headers: {
                          'Authorization': `Bearer ${token}`  // Include the token in the Authorization header
                        }
                      }
                );
                updateChartData(chartData.data);
            } catch (error) {
                console.log(error);
            }
        }
        else {
            console.log("Condition not found !")
          }
        }
        } catch (error) {
            if (error.response && error.response.status === 400) {
              const response = error.response.data;
              // Handle validation errors
              if (response.date1IsBeforedate2 === false) {
                toast.error("Le temps de début doit être antérieur au temps de fin");
              } 
              else if (response.date1IsBeforeNow === false) {
                toast.error("Le temps de début doit être antérieur au temps actuelle");
              } else if (response.date2IsBeforeNow === false) {
                toast.error("Le temps de fin doit être antérieur au temps actuelle");
              } else {
                toast.error("Invalid dates provided.");
              }
            } else {
              console.log(error);
              toast.error("Une erreur s'est produite lors de la vérification des dates");
            }
          }
    };
      function isLater(dateString1, dateString2) {
        return new Date(dateString1) > new Date(dateString2)
      }
    
      function isLaterTime(dateString1, dateString2) {
        return dateString1 > dateString2
      }
      const handleErrors = (e) => {
        if (selected.compresseurs === "") {
          toast.error("Veuillez sélectionner compresseur");
        }
        
        else if (selectedDate.debut === "") {
          toast.error("Veuillez sélectionner date de début");
        }
        else if (selectedDate.fin === "") {
          toast.error("Veuillez sélectionner date de fin");
        }
        // else if ((isLater(selectedDate.debut, selectedDate.fin))) {
        //   toast.error("La date de début doit être anterieur a la date fin")
          
        // }
        // else if ((isLaterTime(selectedTime.timeDebut, selectedTime.timeFin))) {
        //   toast.error("Le temps de début doit être anterieur au temps de fin")
        // } 
        else {
          handleSubmit(e);
        }
      }
  
    const memoizedChart = useMemo(() => {
        if (showCard) {
            return <ChartPression data={DataChart} />;
        }
        return null;
    }, [showCard, DataChart]);

    var dateNow = new Date();
    var day = dateNow.getDate();
    var month = dateNow.getMonth() + 1;
    var year = dateNow.getFullYear()
    if (month < 10)
        month = "0" + month
    if (day < 10)
        day = "0" + day

    return (
        <>
            <div className="page">
                <div className="page-main">
                    <Header />
                    <Sidebar activeItem="pression" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title-pression">
                                            {socketConnected ? (
                                                `Pression en temps réel pour le : ${day}-${month}-${year}`
                                            ) : (
                                                "Flux de données interrompus !"
                                            )}
                                        </h1>
                                    </div>
                                </div>

                                <div className="row">
                                    {compressors !== null && compressors !== undefined ? (
                                        compressors.map((compressor, index) => (
                                            <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3" key={index}>
                                                <div className="card overflow-hidden">
                                                    <div className="card-body">
                                                        <div className="row">

                                                            <h4 className="mb-2">{compressor.name_machine}</h4>
                                                            <p className="text-muted fs-18 mb-0 fw-semibold">{Number(compressor.pression).toFixed(2)} BAR</p>
                                                            <p className="text-muted mb-0 mt-2 fs-12">
                                                                <span className="icn-box text-success fw-semibold fs-13 me-1 mr-5">
                                                                    <i className="fa fa-clock-o" />
                                                                </span>
                                                                {compressor.time}
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="card"><div className="card-body"> Aucun enregistrement disponible </div></div>
                                    )}
                                </div>


                                <div className="card">
                                    <div className="card-header border-bottom">
                                        <h3 className="card-title">
                                            Visualisation courbe pression
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                        <label className="form-label col-md-2 col-lg-2 col-xl-1">Compresseur</label>
                                            <div className="form-group col-md-4 col-lg-4 col-xl-4">
                                            
                                                <SelectOne
                                                placeholder={"Sélectionnez compresseur ..."}
                                                options={
                                                    compressors.map(element => (
                                                    { value: element.id_machine, label: element.name_machine }
                                                    )
                                                    )}
                                                onChange={(e) => handleChange(e, 'compresseurs')}
                                                menuPlacement="auto"
                                                menuPosition="fixed"
                                                autosize={true}
                                                />
                                        
                                            </div>
                                            <label className="form-label col-md-3 col-lg-3 col-xl-1">
                                                Date
                                            </label>
                                            <div className="col-md-4 col-lg-4 col-xl-3 ">
                                                <DatePicker
                                                    id={"dateDebut"}
                                                    value={selectedDate.debut}
                                                    onChange={(e) => { handleDateChange(e, "debut"); setShowCard(false) }}
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-xl-3 ">
                                                <DatePicker
                                                    id={"dateFin"}
                                                    value={selectedDate.fin}
                                                    onChange={(e) => handleDateChange(e, "fin")}
                                                />
                                            </div>

                                            <label className="form-label col-md-3 col-lg-3 col-xl-1">
                                                Temps
                                            </label>
                                            <div className="col-md-4 col-lg-4 col-xl-3 ">
                                                <TimePicker
                                                    id={"timeDebut"}
                                                    value={selectedTime.timeDebut}
                                                    onChange={(e) => { handleTimeChange(e, "timeDebut"); setShowCard(false) }}
                                                    // disabled={
                                                    //     selected.jour_debut === selected.jour_fin &&
                                                    //         selected.mois_debut === selected.mois_fin &&
                                                    //         selected.annee_debut === selected.annee_fin
                                                    //         ? false
                                                    //         : true
                                                    // }
                                                />
                                            </div>

                                            <div className="col-md-4 col-lg-4 col-xl-3 ">
                                                <TimePicker
                                                    id={"timeFin"}
                                                    value={selectedTime.timeFin}
                                                    onChange={(e) => { handleTimeChange(e, "timeFin"); setShowCard(false) }}
                                                    // disabled={
                                                    //     selected.jour_debut === selected.jour_fin &&
                                                    //         selected.mois_debut === selected.mois_fin &&
                                                    //         selected.annee_debut === selected.annee_fin
                                                    //         ? false
                                                    //         : true
                                                    // }
                                                />
                                            </div>

                                            <div className="col-md-12 col-lg-12 col-xl-3">
                                                <button
                                                    className="btn btn-outline-primary btn-block"
                                                    type="submit"
                                                    onClick={(e) => handleErrors(e)}
                                                >
                                                    <i className="fa fa-plus-circle me-2"></i>
                                                    Valider
                                                </button>
                                                <ToastContainer
                                                    className="toast-container"
                                                    autoClose={4000}
                                                    closeButton={false}
                                                    theme={"colored"}
                                                    icon={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {memoizedChart}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Pression;
